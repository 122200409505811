import * as React from "react";
import { Link, graphql } from "gatsby";
import { kebabCase } from "lodash";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Album from "components/Album";
import { Grid, GridItem } from "components/Grid/Grid";
import Layout from "components/Layout";
import Number from "components/Number";
import { toMinutes } from "utils";

import * as styles from "./album.module.scss";


const AlbumPage = ({ data, pageContext }) => {
  const {
    year,
    spotify,
    tracks,
    links,
    tags,
  } = data.albumsYaml;
  const { next, previous } = data;
  const nextImage = getImage(next?.cover);
  const prevImage = getImage(previous?.cover);

  return (
    <Layout breadcrumb={`${data.albumsYaml.number} ${data.albumsYaml.title}`}>
      <Grid className={styles.number}>
        <GridItem cols={2}>
          <Number data={data.albumsYaml} />
        </GridItem>
      </Grid>
      <Grid className={styles.album}>
        <GridItem>
          <Album data={data.albumsYaml} />
        </GridItem>
        <GridItem>
          {tracks && <table className={styles.tracks}>
            <tbody>
            {tracks.map(track => (
              <tr key={track.number}>
                <td>{track.number}</td>
                <td>{track.name}</td>
                <td className={styles.duration}>{toMinutes(track.duration)}</td>
              </tr>
            ))}
            </tbody>
          </table>}
          {(tracks || year) && <dl className={styles.meta}>
            {tracks && <>
              <dt>Length</dt>
              <dd>{toMinutes(tracks.reduce((all, track) => track.duration + all,0))}</dd>
            </>}
            {year && <>
              <dt>Released</dt>
              <dd>
                <Link to={`/${year}`}>{year}</Link>
              </dd>
            </>}
          </dl>}
          {tags && <ul className={styles.tags}>
            {tags.map(tag => (
              <li key={tag}>
                {tag}
              </li>
            ))}
          </ul>}
          {links && <ul className={styles.links}>
            {links.map(link => (
              <li key={link.label}>
                <a href={link.url}>{link.label}</a>
              </li>
            ))}
          </ul>}
          {spotify && <iframe
            className={styles.spotify}
            title="player"
            src={`https://open.spotify.com/embed/album/${spotify}?utm_source=generator&theme=0`}
            width="100%" height={tracks ? 80 : 380}
            frameBorder="0" allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          />}
        </GridItem>
      </Grid>
      <Grid>
        <GridItem cols={2}>
          <div className={styles.nav}>
            {previous &&
              <Link to={"/" + kebabCase(`${previous.number}-${previous.title}`)} className={styles.direction}>
                {prevImage && <GatsbyImage image={prevImage} alt="" width={20} />}
                <span>After</span>
              </Link>
            }
            {next &&
              <Link to={"/" + kebabCase(`${next.number}-${next.title}`)} className={styles.direction}>
                <span>Before</span>
                {nextImage && <GatsbyImage image={nextImage} alt="" />}
              </Link>
            }
          </div>
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default AlbumPage;

export const pageQuery = graphql`
  query AlbumById(
    $id: String!
    $previousAlbumId: String!
    $nextAlbumId: String!
  ) {
    albumsYaml(
  		id: { eq: $id }
    ) {
      artist
      cover {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 800
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      cover_external
      host {
        name
        display_name
      }
      id
      number
      played_on
      title
      year
      spotify
      tracks {
        duration
        name
        number
      }
      links {
        label
        url
      }
      tags
    }
    previous: albumsYaml(id: { eq: $previousAlbumId }) {
      number
      title
      cover {
        childImageSharp {
          gatsbyImageData(width: 50, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    next: albumsYaml(id: { eq: $nextAlbumId }) {
      number
      title
      cover {
        childImageSharp {
          gatsbyImageData(width: 50, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = ({ data }) => <title>{data.albumsYaml.title} - {data.site.siteMetadata.title}</title>;
